<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">档案配置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                    size="small"
                    :props="{
                      emitPath: false,
                      value: 'id',
                      label: 'label',
                      children: 'children',
                      checkStrictly: true,
                    }"
                    v-model="trainTypeId"
                    :options="trainTypeList"
                    clearable
                    @change="handleTrainType"
                  ></el-cascader>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
          <div class="btnBox">
            <el-button class="bgc-bv" round @click="handleEdit('add')">配置档案</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
            label="序号"
            align="center"
            type="index"
            width="50px"
            :index="indexMethod"
          />
              <el-table-column label="行政区划" align="center" prop="areaName" show-overflow-tooltip />
              <el-table-column label="培训类型" align="center" show-overflow-tooltip>
                <span slot-scope="scope">{{scope.row.trainTypeName}}</span>
              </el-table-column>
              <el-table-column label="有效期" align="center" show-overflow-tooltip>
                <span slot-scope="scope">{{scope.row.expirationStartDate?scope.row.expirationStartDate + '~' + scope.row.expirationEndDate:''}}</span>
              </el-table-column>
              <el-table-column label="操作" align="center" width="110px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleEdit('edit',scope.row.paraId)"
                  >编辑</el-button>
                  <!-- <el-button
                    :disabled="scope.row.parentId == 0"
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.id)"
                  >删除</el-button> -->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/roleList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      areaId: "",
      trainTypeList: [],
      trainTypeId:'',
    };
  },
  computed: {},
   mounted() {
    this.getareatree();
    this.getTraintype();
  },
  methods: {
       //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
     /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      this.doFetch({
        url: "/biz/archives/parameter/pageList",
        params,
        pageNum
      });
    },
    //
    handleEdit(stu, paraId= "") {
      this.$router.push({
        path: "/web/operate/fileSetupEdit",
        query: { paraId, stu}
      });
    },
    // 删除
    handleDel(id) {
      this.$confirm("是否确认删除该角色？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("/sys/per/role/delete", { deleted: true, id }).then(
            res => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
                this.getData(-1)
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == 'true') {
         
          this.getData(-1);
        }
         if(oldVal.query.stu =='add'){
            this.areaId = '',
            this.trainTypeId = '',
            this.pageNum= 1,
            this.getData(-1);
          }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
